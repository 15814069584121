<template>
  <b-row>

    <b-col
      md="3"
    >
      <b-card
        :title="form.slug? 'Modifier une devise' : 'Ajouter une devise' "
      >
        <b-form @submit.prevent="form.slug?editDevise():storeDevise()">
          <b-row>

            <b-col cols="12">
              <b-form-group
                label="Nom"
                label-for="libelle"
              >
                <b-form-input
                  id="libelle"
                  v-model="form.libelle"
                  :formatter="formatter"
                  placeholder="Nom"
                />
                <span
                  v-if="errors.libelle"
                  class="text-danger fs-6"
                  style="font-size:11px"
                  v-text="errors.libelle[0]"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="form.description"

                  placeholder="Description"
                  rows="3"
                />
              </b-form-group>
              <span
                v-if="errors.description"
                class="text-danger fs-6"
                style="font-size:11px"
                v-text="errors.description[0]"
              />
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 btn-block"
                size="sm"
                :disabled="deviseProcess"
              >
                {{ form.slug? 'Modifier' : 'Ajouter' }}
                <b-spinner
                  v-if="deviseProcess"
                  small
                  label="Loading..."
                  variant="light"
                  class="ml-1"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
    <b-col
      md="9"
    >
      <b-card
        title="Liste des devises"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="devises"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="getDevise(data.item)"
              >
                <feather-icon
                  icon="EditIcon"
                />
                Modifier
                <span class="align-middle text-primary ml-50" />
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                Supprimer
                <span class="align-middle text-danger ml-50" />
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="devises.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BFormInput, BForm,
  BFormTextarea, BSpinner,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useDevises from '@/services/admin/deviseservice'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormTextarea,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      devises, getDevises, createDevise, errors, deviseProcess, deviseSuccess, devise, updateDevise, loader
    } = useDevises()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'libelle', label: 'Libellé', sortable: true },
      { key: 'description', label: 'Description', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      libelle: '',
      description: '',
    })
    /* eslint-disable global-require */
    const items = devises

    onMounted(() => {
      getDevises()
      // Set the initial number of items
      totalRows.value = items.length
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    // Reset Form
    const resetForm = () => {
      form.libelle = null
      form.description = null
      form.slug = null
    }

    // Enregistrer une devise
    const storeDevise = async () => {
      // showToast('warning', 'StarIcon')
      await createDevise({ ...form }).then(() => {
        if (deviseSuccess.value === true) {
          resetForm()
        }
      })
    }

    // Obtenir une devise par son slug
    const getDevise = async item => {
      form.libelle = item.libelle
      form.description = item.description
      form.slug = item.slug
    }

    // Modifier une devise
    const editDevise = async () => {
      await updateDevise(form).then(() => {
        if (deviseSuccess.value === true) {
          resetForm()
        }
      })
    }
    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      items,
      onFiltered,
      devises,
      form,
      errors,
      storeDevise,
      // validateState,
      formatter,
      deviseProcess,
      devise,
      getDevise,
      editDevise,
      loader,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
@/services/admin/deviseService